@import "../../global_variables.scss";

.ideabot-chat-header {
  background: #1d245e;
  padding: 10px 15px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $theme-font-color;
  height: 40px;

  .header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ideabot-avatar-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    p {
      color: #fff;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 22px;
      padding-left: 15px;
    }
  }
  .close-chat {
    cursor: pointer;
    width: 21px;
    padding-right: 0px;
  }
}
