@import "../global_variables.scss";

// styles
.ideabot-chatBtn {
	display: flex;
	width: 60px;
	height: 60px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
}

.ideabot-chatBtn img {
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.ideabot-chat-widget {
	// width: 374px;
	height: 64px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: $widget-bg-color;
	border-radius: 24px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
	transition: height 0 -3s ease-in-out;
	overflow: hidden;
}

.ideabot-chat-widget.open {
	height: 741px;
	width: 457px;
	z-index: 999;
}

.ideabot-chat-messages {
	flex: 1;
	flex-direction: column;
	padding: 10px;
	overflow-y: scroll;
}

@media only screen and (max-width: 1920px) {
	.ideabot-chat-widget.open {
		height: 80vh;
		width: 390px;
	}
}

@media only screen and (max-width: 1440px) {
	.ideabot-chat-widget.open {
		height: 80vh; //610px;
		width: 390px;
	}
}

@media only screen and (max-width: 1360px) {
	.ideabot-chat-widget.open {
		height: 80vh; //600px;
		width: 380px;
	}
}

@media only screen and (max-width: 1024px) {
	.ideabot-chat-widget.open {
		height: 80vh; //590px;
		width: 380px;
	}
}

@media only screen and (max-width: 768px) {
	.ideabot-chat-widget.open {
		width: 340px;
		height: 525px;
	}
}

@media only screen and (max-width: 600px) {
	.ideabot-chat-widget.open {
		width: 330px;
		height: 525px;
	}
}

@media only screen and (max-width: 411px) {
	.ideabot-chat-widget.open {
		width: 301px;
		height: 490px;
	}
}

@media only screen and (max-width: 375px) {
	.ideabot-chat-widget.open {
		width: 301px;
		height: 490px;
	}

	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span {
		font-size: 12px;
	}
}

@media only screen and (max-width: 340px) {
	.ideabot-chat-widget.open {
		width: 280px;
		height: 440px;
	}

	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span {
		font-size: 12px;
	}
}

@media only screen and (max-width: 300px) {
	.ideabot-chat-widget.open {
		width: 235px;
		height: 440px;
	}
}

@media only screen and (max-width: 280px) {
	.ideabot-chat-widget.open {
		width: 245px;
		height: 440px;
	}

	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span {
		font-size: 10px;
	}
}

@media only screen and (max-width: 240px) {
	.ideabot-chat-widget.open {
		width: 213px;
		height: 440px;
	}

	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span {
		font-size: 10px;
	}
}

.ideabot-chat-widget {
	::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px grey;
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: grey;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: grey;
	}
}
