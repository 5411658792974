@import '../../global_variables.scss';

.ideabot-initiative-buble{
    padding:1px 1px;
    border-radius: 79px;
    background: #00F3CE; //linear-gradient( #00EFCF 100%, #D3ED7D 100%);
    margin-top:15px;
    box-sizing: border-box;
    cursor: pointer;
    .ideabot-button {
        padding: 8px 15px;
        border-radius: 78px;
        background-color: #DAFFF9;

        &:hover {
            background-color: #e9fbf9;
        }
        
        p{
            font-family: 'Manrope', sans-serif;
            line-height: 1.5;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: #97999B;
        }
    }
}