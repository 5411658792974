@import "../../global_variables.scss";

.ideabot-chat-input {
	display: flex;
	border: none;
  padding: 8px 10px;
  background: linear-gradient(107.71deg, #effaf1, #edf3ff 49.48%, #fffce3);
	form {
    border-radius: 12px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex: 1;
		textarea {
			display: flex;
			width: 100%;
			border-radius: 12px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
			border: none;
			margin-right: 0px;
			font-family: "Manrope", sans-serif;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			color: #333333;
			line-height: 1.5;
			padding: 10px 10px;
			max-height: 20px;
			resize: none;
			overflow: hidden;
			touch-action: manipulation;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			user-select: none;
			&:focus-visible {
				border: none;
			}

			&:focus {
				outline: none !important;
			}
		}

		.ideabot-button-wrapper {
			display: flex;
			flex: 0.1;
      height: 100%;
			button {
        border-radius: 12px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
				background-color: white;
				color: #fff;
				border: none;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				.ideabot-chat-message-avatar {
					width: 24px;
					// height: auto;
				}

				&:hover {
					opacity: 0.8;
				}
			}

			button:hover {
				background-color: white;
			}
		}
	}
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	textarea {
		font-size: 12px;

		&::placeholder {
			font-size: 12px;
		}
	}
}

@media (min-width: 768px) {
	textarea {
		font-size: 12px;

		&::placeholder {
			font-size: 12px;
		}
	}
}
