@import "../../global_variables.scss";

// styles chat component
.ideabot-chat-messages {
	padding: 15px 15px 15px 15px;
	position: relative;
	background: linear-gradient(107.71deg, #effaf1, #edf3ff 49.48%, #fffce3);
	.ideabot-chat-message-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;
		.ideabot-initiative-message {
			margin-left: 15px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}
		.ideabot-user-message-row {
			display: flex;
			flex: 1;
			flex-direction: row-reverse;
			.ideabot-avatar-wrapper {
				display: flex;
				position: relative;
				background-color: #040044;
				img {
					width: 25px;
					position: absolute;
					top: 5px;
					right: 5px;
				}
			}
			.ideabot-user-message {
				max-width: 80%;
				background-color: #fff;
				color: #040044;
				align-self: flex-end;
				border-radius: 24px 24px 0px 24px;
				margin: 15px 15px 0px 0px;
				padding: 10px 15px;
				word-wrap: break-word;
				border: 1px solid;
				border-color: #A9EA00;
				p {
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					color: #1D245E;
					opacity: 0.9;
				}
			}
		}
		.ideabot-bot-message-row {
			display: flex;
			flex: 1;
			flex-direction: row;
			.ideabot-avatar-wrapper {
				display: flex;
				background-color: #040044;
				position: relative;
				img {
					width: 25px;
					position: absolute;
					bottom: 0px;
				}
			}
			.ideabot-bot-message {
				display: flex;
				background-color: #a9faef;
				color: #040044;
				align-self: flex-end;
				border-radius: 24px 24px 24px 0px;
				margin: 15px 0px 0px 15px;
				padding: 10px 15px;
				max-width: 80%;
				p {
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					color: #1d245e;
				}
			}
		}
	}
}

.bubble-arrow {
	position: absolute;
	bottom: 110px;
	right: 11px;
	background: #f4f4f4;
	width: 24px;
	height: 24px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 99;
	border: none;
	img {
		margin-top: 4px;
		width: 15px;
		height: 15px;
	}
	:hover {
		cursor: pointer;
	}
}

@media only screen and (max-width: 375px) {
	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span,
	.ideabot-chat-message-wrapper
		.ideabot-user-message-row
		.ideabot-user-message
		span {
		font-size: 12px;
	}
}

@media only screen and (max-width: 340px) {
	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span,
	.ideabot-chat-message-wrapper
		.ideabot-user-message-row
		.ideabot-user-message
		span {
		font-size: 12px;
	}
}

@media only screen and (max-width: 280px) {
	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span,
	.ideabot-chat-message-wrapper
		.ideabot-user-message-row
		.ideabot-user-message
		span {
		font-size: 12px;
	}
}
