@import "https://fonts.googleapis.com/css?family=Manrope";
body, p, h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: Manrope, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.ideabot-chatBtn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.ideabot-chatBtn img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ideabot-chat-widget {
  height: 64px;
  transition: height 0 -3s ease-in-out;
  background-color: #fff;
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
}

.ideabot-chat-widget.open {
  height: 741px;
  width: 457px;
  z-index: 999;
}

.ideabot-chat-messages {
  flex-direction: column;
  flex: 1;
  padding: 10px;
  overflow-y: scroll;
}

@media only screen and (max-width: 1920px) {
  .ideabot-chat-widget.open {
    height: 80vh;
    width: 390px;
  }
}

@media only screen and (max-width: 1440px) {
  .ideabot-chat-widget.open {
    height: 80vh;
    width: 390px;
  }
}

@media only screen and (max-width: 1360px) {
  .ideabot-chat-widget.open {
    height: 80vh;
    width: 380px;
  }
}

@media only screen and (max-width: 1024px) {
  .ideabot-chat-widget.open {
    height: 80vh;
    width: 380px;
  }
}

@media only screen and (max-width: 768px) {
  .ideabot-chat-widget.open {
    width: 340px;
    height: 525px;
  }
}

@media only screen and (max-width: 600px) {
  .ideabot-chat-widget.open {
    width: 330px;
    height: 525px;
  }
}

@media only screen and (max-width: 411px) {
  .ideabot-chat-widget.open {
    width: 301px;
    height: 490px;
  }
}

@media only screen and (max-width: 375px) {
  .ideabot-chat-widget.open {
    width: 301px;
    height: 490px;
  }

  .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 340px) {
  .ideabot-chat-widget.open {
    width: 280px;
    height: 440px;
  }

  .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 300px) {
  .ideabot-chat-widget.open {
    width: 235px;
    height: 440px;
  }
}

@media only screen and (max-width: 280px) {
  .ideabot-chat-widget.open {
    width: 245px;
    height: 440px;
  }

  .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message span {
    font-size: 10px;
  }
}

@media only screen and (max-width: 240px) {
  .ideabot-chat-widget.open {
    width: 213px;
    height: 440px;
  }

  .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message span {
    font-size: 10px;
  }
}

.ideabot-chat-widget ::-webkit-scrollbar {
  width: 4px;
}

.ideabot-chat-widget ::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px gray;
}

.ideabot-chat-widget ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.ideabot-chat-widget ::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.ideabot-chat-header {
  color: #fff;
  height: 40px;
  background: #1d245e;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  display: flex;
}

.ideabot-chat-header .header-row {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.ideabot-chat-header .header-row .ideabot-avatar-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ideabot-chat-header .header-row .ideabot-avatar-container img {
  width: 18px;
  height: 18px;
}

.ideabot-chat-header .header-row p {
  color: #fff;
  padding-left: 15px;
  font-family: Manrope;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.ideabot-chat-header .close-chat {
  cursor: pointer;
  width: 21px;
  padding-right: 0;
}

.ideabot-chat-messages {
  background: linear-gradient(107.71deg, #effaf1, #edf3ff 49.48%, #fffce3);
  padding: 15px;
  position: relative;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-initiative-message {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15px;
  display: flex;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-user-message-row {
  flex-direction: row-reverse;
  flex: 1;
  display: flex;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-user-message-row .ideabot-avatar-wrapper {
  background-color: #040044;
  display: flex;
  position: relative;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-user-message-row .ideabot-avatar-wrapper img {
  width: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-user-message-row .ideabot-user-message {
  max-width: 80%;
  color: #040044;
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #a9ea00;
  border-radius: 24px 24px 0;
  align-self: flex-end;
  margin: 15px 15px 0 0;
  padding: 10px 15px;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-user-message-row .ideabot-user-message p {
  color: #1d245e;
  opacity: .9;
  font-family: Manrope, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-bot-message-row {
  flex-direction: row;
  flex: 1;
  display: flex;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-avatar-wrapper {
  background-color: #040044;
  display: flex;
  position: relative;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-avatar-wrapper img {
  width: 25px;
  position: absolute;
  bottom: 0;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message {
  color: #040044;
  max-width: 80%;
  background-color: #a9faef;
  border-radius: 24px 24px 24px 0;
  align-self: flex-end;
  margin: 15px 0 0 15px;
  padding: 10px 15px;
  display: flex;
}

.ideabot-chat-messages .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message p {
  color: #1d245e;
  font-family: Manrope, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.bubble-arrow {
  width: 24px;
  height: 24px;
  z-index: 99;
  background: #f4f4f4;
  border: none;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 110px;
  right: 11px;
}

.bubble-arrow img {
  width: 15px;
  height: 15px;
  margin-top: 4px;
}

.bubble-arrow :hover {
  cursor: pointer;
}

@media only screen and (max-width: 375px) {
  .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message span, .ideabot-chat-message-wrapper .ideabot-user-message-row .ideabot-user-message span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 340px) {
  .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message span, .ideabot-chat-message-wrapper .ideabot-user-message-row .ideabot-user-message span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 280px) {
  .ideabot-chat-message-wrapper .ideabot-bot-message-row .ideabot-bot-message span, .ideabot-chat-message-wrapper .ideabot-user-message-row .ideabot-user-message span {
    font-size: 12px;
  }
}

.ideabot-initiative-buble {
  box-sizing: border-box;
  cursor: pointer;
  background: #00f3ce;
  border-radius: 79px;
  margin-top: 15px;
  padding: 1px;
}

.ideabot-initiative-buble .ideabot-button {
  background-color: #dafff9;
  border-radius: 78px;
  padding: 8px 15px;
}

.ideabot-initiative-buble .ideabot-button:hover {
  background-color: #e9fbf9;
}

.ideabot-initiative-buble .ideabot-button p {
  color: #97999b;
  font-family: Manrope, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.ideabot-bot-message {
  background-color: #6c757d;
  border-radius: 0 20px 20px;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
}

.ideabot-typing-wrapper {
  flex-direction: row;
  padding: 15px 0;
  display: flex;
}

.ideabot-typing-wrapper .ideabot-avatar-wrapper {
  flex-direction: row;
  display: flex;
  position: relative;
}

.ideabot-typing-wrapper .ideabot-avatar-wrapper img {
  width: 25px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.ideabot-typing-wrapper .ideabot-bot-message {
  background-color: #f4f4f4;
  border-radius: 0 24px 24px;
  flex-direction: row;
  margin: 10px 0 0 18px;
  padding: 5.5px 15px;
  display: flex;
}

.ideabot-typing-wrapper .ideabot-bot-message .bouncing-loader {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  display: flex;
}

.ideabot-typing-wrapper .ideabot-bot-message .bouncing-loader > div {
  width: 10px;
  height: 10px;
  opacity: 1;
  background-color: #ccc8c8;
  border-radius: 50%;
  margin: 5px;
  -webkit-animation: .6s infinite alternate bouncing-loader;
  animation: .6s infinite alternate bouncing-loader;
}

@-webkit-keyframes bouncing-loader {
  to {
    opacity: .1;
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes bouncing-loader {
  to {
    opacity: .1;
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

.ideabot-typing-wrapper .ideabot-bot-message .bouncing-loader > div:nth-child(2) {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.ideabot-typing-wrapper .ideabot-bot-message .bouncing-loader > div:nth-child(3) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.ideabot-chat-input {
  background: linear-gradient(107.71deg, #effaf1, #edf3ff 49.48%, #fffce3);
  border: none;
  padding: 8px 10px;
  display: flex;
}

.ideabot-chat-input form {
  border-radius: 12px;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ideabot-chat-input form textarea {
  width: 100%;
  color: #333;
  max-height: 20px;
  resize: none;
  touch-action: manipulation;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 12px 0 0 12px;
  margin-right: 0;
  padding: 10px;
  font-family: Manrope, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  overflow: hidden;
}

.ideabot-chat-input form textarea:focus-visible {
  border: none;
}

.ideabot-chat-input form textarea:focus {
  outline: none !important;
}

.ideabot-chat-input form .ideabot-button-wrapper {
  height: 100%;
  flex: .1;
  display: flex;
}

.ideabot-chat-input form .ideabot-button-wrapper button {
  color: #fff;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 0 12px 12px 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ideabot-chat-input form .ideabot-button-wrapper button .ideabot-chat-message-avatar {
  width: 24px;
}

.ideabot-chat-input form .ideabot-button-wrapper button:hover {
  opacity: .8;
  background-color: #fff;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  textarea {
    font-size: 12px;
  }

  textarea::-webkit-input-placeholder {
    font-size: 12px;
  }

  textarea::placeholder {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  textarea {
    font-size: 12px;
  }

  textarea::-webkit-input-placeholder {
    font-size: 12px;
  }

  textarea::placeholder {
    font-size: 12px;
  }
}

.ideabot-footer {
  height: 28px;
  border-top: 1px solid #d6d6d6;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ideabot-footer .ideabot-chat-footer-powered-by {
  width: 90px;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ideabot-footer .ideabot-chat-footer-version {
  color: #53575f;
  font-family: Manrope;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  position: fixed;
  right: 36px;
}

/*# sourceMappingURL=index.css.map */
